<template>
  <div class="pa-0">
    <div class="mt-15 px-8" v-if="isLoad">
      <v-row class="px-15">
        <v-col cols="12" v-for="i in 5" :key="i" class="text-left">
          <v-skeleton-loader type="paragraph" class="mt-3"></v-skeleton-loader>
        </v-col>
      </v-row>
    </div>
    <div class="grid justify-items-center px-0 py-10" v-else>
      <v-col
        cols="11"
        class="pa-0 mt-10 pt-10"
        v-if="items.length > 0 || news.length > 0 || itemsStories.length > 0">
        <v-row class="px-3">
          <v-col cols="12" class="text-left mb-10">
            <span class="font-bold text-3xl text-capitalize" v-if="bahasa"
              >Publikasi</span
            >
            <span class="font-bold text-3xl text-capitalize" v-else
              >Publication</span
            >
          </v-col>
        </v-row>
        <v-tabs v-model="tab" class="stories px-3">
          <v-tab href="#tab-1" class="">
            <span v-if="bahasa">Siaran Pers</span>
            <span v-else>Press Conference</span>
          </v-tab>
          <v-tab href="#tab-2" class="">
            <span v-if="bahasa">Berita</span>
            <span v-else>News</span>
          </v-tab>
          <v-tab href="#tab-3" class="">
            <span v-if="bahasa">Kisah</span>
            <span v-else>Stories</span>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item value="tab-1" class="pa-0">
            <v-row class="mt-3" v-if="isLoad">
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-6 cursor-pointer"
                v-for="i in 4"
                :key="i">
                <v-skeleton-loader
                  type="paragraph"
                  class="mt-5"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="mt-3" v-else-if="!isLoad && items.length > 0">
              <v-col
                cols="12"
                sm="4"
                v-for="(i, index) in items"
                :key="index"
                class="px-5 text-left cursor-pointer"
                @click="goTo('news-detail', i.id)">
                <img
                  :src="baseUrlImage + i.image.file_path"
                  width="100%"
                  @error="
                    $event.target.src =
                      'https://admin.bpdlh.id/assets/empty.png'
                  "
                  class="rounded-md h-60 img-card" />
                <div class="font-bold text-base sm:text-xl pt-2">
                  {{ i.title || "-" }}
                </div>
                <div class="text-base font-lora pt-1 pb-2 pa-0">
                  <div v-html="strLimit(i.desc, 200)" />
                </div>
              </v-col>
            </v-row>
            <v-row class="text-left mt-3 py-5" v-if="items.length > 0">
              <v-spacer />
              <v-col cols="auto">
                <v-pagination
                  v-model="page"
                  :length="pageCount"
                  color="green darken-1"
                  next-icon="navigate_next"
                  prev-icon="chevron_left"></v-pagination>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" sm="6">
                <img src="@/assets/images/405.png" width="100%" />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="text-black flex flex-wrap content-center">
                <v-col cols="12" class="text-3xl font-bold pl-0">{{
                  bahasa ? "Tidak Ada Data Tersedia" : "No Data  Available"
                }}</v-col>
                <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
                  {{ bahasa ? "kembali ke beranda" : "back to home" }}
                </v-btn>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="tab-2" class="pa-0">
            <v-row class="mt-3" v-if="isLoadNews">
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-6 cursor-pointer"
                v-for="i in 4"
                :key="i">
                <v-skeleton-loader
                  type="paragraph"
                  class="mt-5"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row class="mt-3" v-else-if="!isLoadNews && news.length > 0">
              <v-col
                cols="12"
                sm="7"
                class="text-left cursor-pointer"
                @click="goTo('news-detail', news[0].id)">
                <img
                  :src="baseUrlImage + news[0].image.file_path"
                  @error="
                    $event.target.src =
                      'https://admin.bpdlh.id/assets/empty.png'
                  "
                  width="100%"
                  class="rounded-md" />
                <div class="font-bold text-2xl sm:text-3xl pa-0 mt-5">
                  {{ news[0] ? news[0].title : "-" }}
                </div>
                <div class="text-sm py-2 pa-0">
                  {{ news[0] ? news[0].lastUpdate : "" }}
                </div>
                <div
                  class="font-lora pa-0 text-base h-24 sm:h-14"
                  v-html="strLimit(news[0].desc, 100)"></div>
                <v-btn
                  @click="goTo('news-detail', news[0].id)"
                  class="btn-primary mt-3 font-bold text-capitalize">
                  <span v-if="bahasa">Baca selengkapnya</span>
                  <span v-else>Read More</span>
                </v-btn>
              </v-col>
              <v-col cols="12" sm="5" class="text-left pt-0">
                <div
                  class="flex mb-2 cursor-pointer"
                  @click="goTo('news-detail', item.id)"
                  v-for="(item, index) in news"
                  :key="index + 'news'">
                  <template v-if="index > 0">
                    <v-col cols="3" class="px-0">
                      <img
                        :src="baseUrlImage + item.image.file_path"
                        @error="
                          $event.target.src =
                            'https://admin.bpdlh.id/assets/empty.png'
                        "
                        width="100%"
                        class="rounded-md sm:px-3" />
                    </v-col>
                    <v-col cols="9">
                      <div class="font-bold text-base pt-0 sm:text-xl">
                        {{ item.title || "-" }}
                      </div>
                      <div class="text-sm py-2 pa-0">
                        {{ item.lastUpdate || "-" }}
                      </div>
                    </v-col>
                  </template>
                </div>
              </v-col>
            </v-row>
            <div class="mt-10">
              <v-col cols="12" class="text-2xl font-bold text-left mb-4">{{
                bahasa ? "Berita lalu" : "Older"
              }}</v-col>
              <v-skeleton-loader
                type="paragraph"
                class="mt-3"
                v-if="isLoadNewsPast"></v-skeleton-loader>
              <v-row v-if="newsPast.length > 0">
                <v-col
                  cols="12"
                  sm="4"
                  v-for="(item, index) in newsPast"
                  :key="index"
                  class="px-5 text-left cursor-pointer"
                  @click="goTo('news-detail', item.id)">
                  <img
                    :src="baseUrlImage + item.image.file_path"
                    @error="
                      $event.target.src =
                        'https://admin.bpdlh.id/assets/empty.png'
                    "
                    width="100%"
                    class="rounded-md object-cover" />
                  <div class="font-bold text-base sm:text-xl py-2">
                    {{ item.title || "-" }}
                  </div>
                  <div class="text-sm py-2 pa-0">
                    {{ item.lastUpdate || "-" }}
                  </div>
                  <div
                    class="font-lora pa-0 text-base h-24 sm:h-14"
                    v-html="strLimit(item.desc, 100)" />
                </v-col>
              </v-row>
            </div>
            <v-row class="text-left mt-3 py-5" v-if="newsPast.length > 0">
              <v-spacer />
              <v-col cols="auto">
                <v-pagination
                  v-model="pageNews"
                  :length="pageCountNews"
                  color="green darken-1"
                  next-icon="navigate_next"
                  prev-icon="chevron_left"></v-pagination>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" sm="6">
                <img src="@/assets/images/405.png" width="100%" />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="text-black flex flex-wrap content-center">
                <v-col cols="12" class="text-3xl font-bold pl-0">{{
                  bahasa ? "Tidak Ada Data Tersedia" : "No Data  Available"
                }}</v-col>
                <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
                  {{ bahasa ? "kembali ke beranda" : "back to home" }}
                </v-btn>
              </v-col>
            </v-row>
          </v-tab-item>
          <v-tab-item value="tab-3" class="pa-0">
            <v-row class="mt-3" v-if="isLoadStoies">
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-6 cursor-pointer"
                v-for="i in 4"
                :key="i">
                <v-skeleton-loader
                  type="paragraph"
                  class="mt-5"></v-skeleton-loader>
              </v-col>
            </v-row>
            <v-row
              class="mt-3"
              v-else-if="!isLoadStoies && itemsStories.length > 0">
              <v-col
                cols="12"
                sm="6"
                class="text-left pa-6 cursor-pointer"
                @click="goToStories('content-detail', '/kisah', i.id)"
                v-for="(i, index) in itemsStories"
                :key="index">
                <v-img
                  :src="baseUrlImage + i.image.file_path"
                  class="rounded align-center pa-5 w-screen"
                  height="250">
                  <v-col
                    cols="12"
                    class="rounded-md border border-white-500 pa-5 bg-blur text-white">
                    <div class="text-sm">
                      {{ bahasa ? "Kisah" : "Stories" }}
                    </div>
                    <div class="text-xl sm:text-2xl tracking-wide py-2">
                      {{ i.title || "-" }}
                    </div>
                    <v-btn
                      class="btn-primary-white mt-3 font-bold text-capitalize">
                      <span>{{
                        bahasa ? "Baca selengkapnya" : "Read More"
                      }}</span>
                    </v-btn>
                  </v-col>
                </v-img>
              </v-col>
            </v-row>
            <v-row class="text-left mt-3 py-5" v-if="itemsStories.length > 0">
              <v-spacer />
              <v-col cols="auto">
                <v-pagination
                  v-model="pageStories"
                  :length="pageCountStories"
                  color="green darken-1"
                  next-icon="navigate_next"
                  prev-icon="chevron_left"></v-pagination>
              </v-col>
            </v-row>
            <v-row v-else>
              <v-col cols="12" sm="6">
                <img src="@/assets/images/405.png" width="100%" />
              </v-col>
              <v-col
                cols="12"
                sm="6"
                class="text-black flex flex-wrap content-center">
                <v-col cols="12" class="text-3xl font-bold pl-0">{{
                  bahasa ? "Tidak Ada Data Tersedia" : "No Data  Available"
                }}</v-col>
                <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
                  {{ bahasa ? "kembali ke beranda" : "back to home" }}
                </v-btn>
              </v-col>
            </v-row>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col cols="11" class="pa-0 mt-10 py-10" v-else>
        <v-row>
          <v-col cols="12" sm="6">
            <img src="@/assets/images/405.png" width="100%" />
          </v-col>
          <v-col
            cols="12"
            sm="6"
            class="text-black flex flex-wrap content-center">
            <v-col cols="12" class="text-3xl font-bold pl-0">{{
              bahasa ? "Tidak Ada Data Tersedia" : "No Data  Available"
            }}</v-col>
            <v-btn class="btn-primary mt-8" :to="{ name: 'home' }">
              {{ bahasa ? "kembali ke beranda" : "back to home" }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import Swal from "sweetalert2";
export default {
  props: {
    bahasa: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      page: 1,
      pageCount: 0,
      per_page: 10,
      pageNews: 1,
      pageCountNews: 0,
      per_page_news: 9,
      pageStories: 1,
      pageCountStories: 0,
      per_page_stories: 9,
      tab: null,
      isLoad: false,
      isLoadNews: false,
      isLoadNewsPast: false,
      isLoadStoies: false,
      items: [],
      itemsStories: [],
      news: [],
      newsPast: [],
      baseUrl: process.env.VUE_APP_BASE_URL,
      baseUrlImage: process.env.VUE_APP_BASE_ASSET,
    };
  },
  computed: {
    pageName() {
      let path = this.$route.name;
      let page = path.split("/").join("");
      return page.includes("-") ? page.split("-").join(" ") : page;
    },
    language() {
      return this.bahasa ? "ID" : "ENG";
    },
  },
  watch: {
    bahasa() {
      this.getData();
      this.getDataNews();
      this.getDataStory();
    },
    page() {
      this.getData();
    },
    pageNews() {
      this.getDataNewsPast();
    },
    pageStory() {
      this.getDataStory();
    },
  },
  mounted() {
    this.getData();
    this.getDataNews();
    this.getDataNewsPast();
    this.getDataStory();
  },
  methods: {
    goTo(link, param) {
      this.$router.push({
        name: link,
        params: {
          id: param,
        },
      });
    },
    goToStories(link, type, param) {
      this.$router.push({
        name: link,
        params: {
          type: type,
          id: param,
        },
      });
    },
    onImageError(index, image) {
      // console.log(index);
      console.log(image);
      // console.log(this.itemsStories[index].image.file_path);
      this.itemsStories[index].image.file_path =
        "https://admin.bpdlh.id/" + "assets/empty.png";
      // console.log(this.itemsStories[index].image.file_path);
    },

    async getData() {
      this.isLoad = true;
      let param = {
        lang: this.language,
        type: "siaran-pers",
        page: this.page,
        per_page: this.per_page,
      };
      await axios
        .get(this.baseUrl + "/content", { params: param })
        .then((response) => {
          if (response.data.code === 200) {
            this.items = response.data.data.data;
            this.page = response.data.data.current_page;
            this.pageCount = response.data.data.last_page;
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoad = false));
    },

    async getDataNews() {
      this.isLoadNews = true;
      let param = {
        lang: this.language,
        page: this.pageNews,
        option: "NEW",
        type: "berita",
      };
      await axios
        .get(this.baseUrl + "/content", { params: param })
        .then((response) => {
          this.news = response.data.data;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadNews = false));
    },
    async getDataNewsPast() {
      this.isLoadNewsPast = true;
      let param = {
        lang: this.language,
        page: this.pageNews,
        per_page: this.per_page_news,
        option: "OLD",
        type: "berita",
      };
      await axios
        .get(this.baseUrl + "/content", { params: param })
        .then((response) => {
          this.newsPast = response.data.data.data;
          this.pageNews = response.data.data.current_page;
          this.pageCountNews = response.data.data.last_page;
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadNewsPast = false));
    },
    async getDataStory() {
      this.isLoadStoies = true;
      let param = {
        lang: this.language,
        type: "kisah",
        page: this.pageStories,
        per_page: this.per_page_stories,
      };
      await axios
        .get(this.baseUrl + "/content", { params: param })
        .then((response) => {
          if (response.data.code === 200) {
            this.itemsStories = response.data.data.data;
            this.pageStories = response.data.data.current_page;
            this.pageCountStories = response.data.data.last_page;
          }
        })
        .catch((error) => {
          Swal.fire("Failed!", error.response.data.message, "error");
        })
        .finally(() => (this.isLoadStoies = false));
    },
  },
};
</script>
